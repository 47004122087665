<template>
  <a-modal
    title="新建"
    :width="1440"
    :visible="visible"
    :confirmLoading="loading"
    @ok="ok"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-row :gutter="[16,16]" v-show="step1">
        <a-col :span="12">
          <a-button type="dashed" style="width: 100%; height: 150px; margin: 0 auto" @click="nextstep(1)">
            出区
          </a-button>
        </a-col>
        <a-col :span="12">
          <a-button type="dashed" style="width: 100%; height: 150px; margin: 0 auto" @click="nextstep(2)">
            入区
          </a-button>
        </a-col>
      </a-row>
      <a-form :form="form" v-bind="formLayout" v-if="step2">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="方向">
              <h3>  {{ this.$Dictionaries.WarehouseTransferDirection[direction] }}</h3>
            </a-form-item>
            <a-form-model-item label="汇率">
              <a-select v-model="exchange_rate_id" placeholder="请选择" style="width: 30%">
                <a-select-option v-for="item in rate_List" :value="item.value" :key="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="手动增添调拨子单">
              <vxe-toolbar>
                <template v-slot:buttons>
                  <vxe-button @click="handleAdd">新增</vxe-button>
                  <vxe-button @click="$refs.xTable.removeCheckboxRow()">删除选中</vxe-button>
                  <!--                  <vxe-button @click="importItems">通过台账导入</vxe-button>-->
                </template>
              </vxe-toolbar>
              <vxe-table
                border
                resizable
                show-overflow
                ref="xTable"
                :data="tableData"
                :toolbarConfig="{ perfect: false}"
              >
                <vxe-table-column type="checkbox" width="60"></vxe-table-column>
                <vxe-table-column field="bonded_commodity" title="保税货物"></vxe-table-column>
                <vxe-table-column field="bonded_warehouse" title="保税仓位"></vxe-table-column>
                <vxe-table-column field="exhibition_warehouse" title="展示仓"></vxe-table-column>
                <vxe-table-column field="exhibition_commodity" title="展示商品"></vxe-table-column>
                <vxe-table-column field="exhibition_count" title="展示数量"></vxe-table-column>
              </vxe-table>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
    <a-modal
      :footer="null"
      ref="addmodel"
      v-model="addvisible"
      v-if="addvisible"
      title="新增"
      :width="1440" >
      <a-form-model ref="ruleForm" :model="addForm" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-model-item label="保税商品">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                label-in-value
                allow-clear
                v-model="addForm.bonded_commodity_id"
                placeholder="保税商品"
                @search="handleBondedCommoditySearch"
                @change="commodityChangeEvent"
              >
                <a-select-option v-for="d in dataSource_bonded_commodity" :key="d.id" :tt="d.bonded_goods.name + '(' + d.batch+ ')'">
                  {{ d.bonded_goods.name + '(' + d.batch+ ')' }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <!--            <a-form-model-item label="保税商品">
              <a-select
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                v-model="addForm.bonded_commodity_id"
                placeholder="请选择"
                @change="commodityChangeEvent"
                label-in-value>
                <a-select-option v-for="item in bonded_commodity_List" :value="item.value" :key="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>-->
            <a-col :span="24">
              <a-form-model-item label="保税仓位">
                <a-select
                  allow-clear
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  v-model="addForm.warehouse_bonded_commodity_id"
                  @change="outwareHouseChangeEvent"
                  placeholder="请选择"
                  label-in-value>
                  <a-select-option v-for="item in warehouse_bonded_commodity_List" :value="item.value" :key="item.value">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-col></a-row>
        <a-form-model-item label="展示仓库">
          <a-select
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
            v-model="addForm.exhibition_warehouse_id"
            placeholder="请选择"
            @change="exhibition_warehouseChangeEvent"
            label-in-value>
            <a-select-option v-for="item in exhibition_warehouse_List" :value="item.value" :key="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="展示商品">
          <a-select
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
            v-model="addForm.exhibition_commodity_id"
            placeholder="请选择"
            @change="exhibition_commodityChangeEvent"
            label-in-value>
            <a-select-option v-for="item in exhibition_commodity_List" :value="item.value" :key="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="展示商品数量" v-if="exhibition_count_show === 1">
          <a-input-number :min="0" :max="bonded_count" :step="0.01" v-model="addForm.exhibition_count"/>
          /{{ (bonded_count/cur_rate).toFixed(2) }}
        </a-form-model-item>
        <a-form-model-item label="展示商品数量" v-if="exhibition_count_show === 2">
          <a-input-number :min="0" :max="exhibition_count" :step="0.01" v-model="addForm.exhibition_count"/>
          /{{ exhibition_count }}
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 4, offset: 12 }">
          <a-button type="primary" @click="submitForm('ruleForm')">
            确定
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-modal>
</template>

<script>
import 'vxe-table/lib/style.css'
import pick from 'lodash.pick'
import { bonded_commodity_list } from '@/api/bonded_commodity'
import { bonded_warehouse_bonded_commodity_list } from '@/api/bonded_warehouse'
import { exhibition_warehouse_list } from '@/api/exhibition_warehouse'
import { exhibition_commodity_list } from '@/api/exhibition_commodity'
import { exchange_rate_list } from '@/api/exchange_rate'

// 表单字段
const fields = ['id', 'bill_id', 'corporation_id', 'amount', 'account', 'bank',
  'number', 'img', 'status', 'category', 'corporation']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 }
      }
    }
    return {
      dataSource_bonded_commodity: [],
      exchange_rate_id: '',
      step1: true,
      step2: false,
      cur_rate: 1,
      exhibition_count_show: 0,
      outrules: {
        direction: [{ required: true, message: '请输入', trigger: 'change' }]
      },
      rules: {
        bonded_goods_id: [{ required: true, message: '请输入', trigger: 'change' }]
      },
      bonded_count: 0,
      exhibition_count: 0,
      outForm: {},
      addForm: {},
      labelCol: { span: 4, offset: 4 },
      wrapperCol: { span: 10 },
      addvisible: false,
      color: '#3f3f3f',
      form: this.$form.createForm(this),
      dictionaries: this.$Dictionaries,
      list: [],
      allCount: 0,
      bonded_commodity_List: [],
      warehouse_bonded_commodity_List: [],
      exhibition_commodity_List: [],
      exhibition_warehouse_List: [],
      rate_List: [],
      current_obj: {},
      tableData: []
    }
  },
  created () {
    console.log('custom modal created', this.form)
    this.get_exchange_rate_list()
    this.get_bonded_commodity_list()
    this.get_exhibition_warehouse_list()
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    handleBondedCommoditySearch (value) {
      bonded_commodity_list({ name: value }).then((res) => {
        let result = res.data.entries || []
        this.dataSource_bonded_commodity = !value ? [] : result
      })
    },
    handleBondedCommodityChange (value) {
      this.addForm.bonded_commodity_id = value
    },
    filterOption (input, option) {
      console.log(input, option.componentOptions.children[0].text)
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    nextstep (value) {
      this.direction = value
      this.step1 = false
      this.step2 = true
      this.exhibition_count_show = value
    },
    submitForm (formName) {
      console.log(this.$refs.ruleForm)
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.insertEvent(this.addForm)
          this.addvisible = false
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleOk () {
      console.log(11)
    },
    handleAdd () {
      this.addForm = {}
      this.bonded_count = 0
      this.addvisible = true
    },
    get_bonded_commodity_list () {
      bonded_commodity_list({ page_size: 1000 }).then(({ data }) => {
        this.bonded_commodity_List = data.entries || []
        this.bonded_commodity_List = this.bonded_commodity_List.map(item => {
          return {
            label: item.bonded_goods.name + '(' + item.batch + ')',
            value: item.id,
            disabled: false
          }
        })
        this.bonded_commodity_List.unshift({
            label: '请选择',
            value: -1,
            disabled: false
          })
      })
    },
    get_bonded_warehouse_bonded_commodity_list (obj) {
      bonded_warehouse_bonded_commodity_list(obj).then(({ data }) => {
        console.log(data)
        this.warehouse_bonded_commodity_List = data.entries || []
        this.warehouse_bonded_commodity_List = this.warehouse_bonded_commodity_List.map(item => {
          return {
            label: item.bonded_warehouse + '-' + item.bonded_cargo_space,
            value: item.id,
            bonded_warehouse_id: item.bonded_warehouse_id,
            bonded_cargo_space_id: item.bonded_cargo_space_id,
            bonded_count: item.count - item.freeze_count,
            disabled: false
          }
        })
        this.warehouse_bonded_commodity_List.unshift({
          label: '请选择',
          value: -1,
          disabled: false
        })
      })
    },
    get_exhibition_warehouse_list () {
      exhibition_warehouse_list({ page_size: 1000 }).then(({ data }) => {
        console.log(data)
        this.exhibition_warehouse_List = data.entries || []
        this.exhibition_warehouse_List = this.exhibition_warehouse_List.map(item => {
          return {
            label: item.name,
            value: item.id,
            disabled: false
          }
        })
        this.exhibition_warehouse_List.unshift({
          label: '请选择',
          value: -1,
          disabled: false
        })
      })
    },
    get_exhibition_commodity_list (obj) {
      exhibition_commodity_list(obj).then(({ data }) => {
        console.log(data)
        this.exhibition_commodity_List = data.entries || []
        this.exhibition_commodity_List = this.exhibition_commodity_List.map(item => {
          return {
            label: item.name,
            value: item.id,
            rate: item.rate,
            exhibition_count: item.total_exhibition_count,
            disabled: false
          }
        })
        this.exhibition_commodity_List.unshift({
          label: '请选择',
          value: -1,
          disabled: false
        })
      })
    },
    get_exchange_rate_list () {
      exchange_rate_list().then(({ data }) => {
        this.rate_List = data.entries || []
        console.log(this.rate_list)
        this.rate_List = this.rate_List.map(item => {
          return {
            label: item.create_time,
            value: item.id,
            disabled: false
          }
        })
      })
    },
    commodityChangeEvent (value) {
      this.addForm.warehouse_bonded_commodity_id = { key: -1, label: '请选择' }
      this.$forceUpdate()
      this.bonded_count = 0
      let current_obj = this.bonded_commodity_List.find((item) => {
        return item.value === value.key
      })
      this.addForm.bonded_commodity = current_obj.label
      this.get_bonded_warehouse_bonded_commodity_list({ bonded_commodity_id: current_obj.value, page_size: 1000 })
    },
    // 保税仓位
    outwareHouseChangeEvent (obj) {
      this.$forceUpdate()
      this.bonded_count = 0
      this.current_obj = this.warehouse_bonded_commodity_List.find((item) => {
        console.log(item.value)
        return item.value === obj.key
      })
      console.log(this.current_obj, obj.key, '---989')
      this.bonded_count = this.current_obj.bonded_count
      this.addForm.bonded_warehouse = obj.label
      this.addForm.bonded_cargo_space_id = { key: this.current_obj.bonded_cargo_space_id }
      this.addForm.bonded_warehouse_id = { key: this.current_obj.bonded_warehouse_id }
    },
    // 展示仓库
    exhibition_warehouseChangeEvent (obj) {
      this.addForm.exhibition_warehouse = obj.label
      console.log(obj, 'obj111')
      this.exhibition_count = 0
      this.addForm.exhibition_commodity_id = { key: -1, label: '请选择' }
      this.$forceUpdate()
      this.get_exhibition_commodity_list({ exhibition_warehouse_id: obj.key, bonded_commodity_id: this.addForm.bonded_commodity_id.key, page_size: 1000 })
    },
    // 展示商品
    exhibition_commodityChangeEvent (obj) {
      this.$forceUpdate()
      console.log(obj, '123')
      this.addForm.exhibition_commodity = obj.label
      this.exhibition_count = 0
      let current_obj = this.exhibition_commodity_List.find((item) => {
        return item.value === obj.key
      })
      this.cur_rate = current_obj.rate
      console.log(current_obj, 'rate')
      console.log(current_obj, obj.key, '---989')
      this.exhibition_count = current_obj.exhibition_count
    },
    ok () {
      let x = this.$refs.xTable.afterFullData
      console.log(x, '98989')
      // 设置所有{key:id} 对于select label in value做结构重整
      x = x.map((item) => {
        Object.keys(item).forEach((i) => {
          if (i.indexOf('id') > -1) {
            item[i] = item[i].key
          }
        })
        return item
      })
      console.log(x, this.form.getFieldValue('direction'))
      let direction = this.direction
      let obj = {
        sub_list: x,
        exchange_rate_id: this.exchange_rate_id,
        direction: direction
      }
      this.$emit('ok', obj)
    },
    insertEvent (form) {
      console.log(form)
      this.allCount = 0
      const xTable = this.$refs.xTable
      xTable.insert(form)
    },
    onChange (value) {
      console.log(value)
    }
  }
}
</script>
