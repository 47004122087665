import request from '@/utils/request'

const warehouseApi = {
  warehouse_transfer_list: '/warehouse/transfer/',
  warehouse_transfer_create: '/warehouse/transfer/',
  warehouse_transfer_delete: '/warehouse/transfer/',
  warehouse_sub_transfer_list: '/warehouse/sub_transfer/',
  warehouse_sub_transfer_create: '/warehouse/sub_transfer/',
  warehouse_sub_transfer_update: '/warehouse/sub_transfer/',
  warehouse_sub_transfer_delete: '/warehouse/sub_transfer/'
}
/**
 * 列表
 */
export function warehouse_transfer_list (parameter) {
  return request({
    url: warehouseApi.warehouse_transfer_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function warehouse_transfer_create (parameter) {
  return request({
    url: warehouseApi.warehouse_transfer_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 删除
 */
export function warehouse_transfer_delete (warehouse_id) {
  return request({
    url: warehouseApi.warehouse_transfer_delete + warehouse_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 列表
 */
export function warehouse_sub_transfer_list (parameter) {
  return request({
    url: warehouseApi.warehouse_sub_transfer_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function warehouse_sub_transfer_create (parameter) {
  return request({
    url: warehouseApi.warehouse_sub_transfer_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function warehouse_sub_transfer_update (parameter, warehouse_id) {
  return request({
    url: warehouseApi.warehouse_sub_transfer_update + warehouse_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function warehouse_sub_transfer_delete (warehouse_id) {
  return request({
    url: warehouseApi.warehouse_sub_transfer_delete + warehouse_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
